<template>
  <v-main class="ma-0 mt-12 pa-0" v-if="isLogin">
    <div class="h-header mt-8">
      <h1 class="h-title">Welcome</h1>
      <h2 class="h-title3">{{ appName }}</h2>
      <h3 class="h-title2">Experience Store</h3>
    </div>
    <v-carousel
      v-model="index"
      class="mt-16 mb-0"
      height="auto"
      :hide-delimiter-background="true"
      :hide-delimiters="true"
      :show-arrows="false"
    >
      <v-carousel-item>
        <v-row @click="next(0)"
          class="fill-height"
          align="center"
          justify="center"
        >
          <div class="img-wrapper">
            <!-- <h1 class="number">1.</h1> -->
            <img src="@/assets/home1.png" alt="home1" class="img" width="100%">
            <div class="h-sub mt-4">1. Pick your product </div>
          </div>
        </v-row>
      </v-carousel-item>

      <v-carousel-item>
        <v-row @click="next(1)"
          class="fill-height"
          align="center"
          justify="center"
        >
          <div class="img-wrapper">
            <!-- <h1 class="number">2.</h1> -->
            <img src="@/assets/home2.png" alt="home2" class="img" width="45%">
            <span class="h-sub mt-4">2. Scan Product QR </span>
          </div>
        </v-row>
      </v-carousel-item>

      <v-carousel-item>
        <v-row @click="next(2)"
          class="fill-height"
          align="center"
          justify="center"
        >
          <div class="img-wrapper">
            <!-- <h1 class="number">3.</h1> -->
            <img src="@/assets/home3.png" alt="home3" class="img" width="50%">
            <span class="h-sub mt-4">3. Check your booking product on cart </span>
          </div>
        </v-row>
      </v-carousel-item>

      <v-carousel-item>
        <v-row @click="next(3)"
          class="fill-height"
          align="center"
          justify="center"
        >
           <div class="img-wrapper">
             <!-- <h1 class="number">4.</h1> -->
            <img src="@/assets/home4.png" alt="home4" class="img" width="30%">
            <span class="h-sub mt-4">4. Online Payment </span>
          </div>
        </v-row>
      </v-carousel-item>

      <v-carousel-item>
        <v-row @click="next(4)"
          class="fill-height"
          align="center"
          justify="center"
        >
          <div class="img-wrapper">
            <!-- <h1 class="number">5.</h1> -->
            <img src="@/assets/home5.png" alt="home5" class="img" width="60%">
            <span class="h-sub mt-4">5. Your product is ready ! </span>
          </div>
        </v-row>
      </v-carousel-item>
    </v-carousel>
    <div class="mt-16" style="text-align:center">
      <v-icon
        class="mr-1"
        :color="index === i ? '#8B1724' : '#C0C0C0'"
        @click="index = i"
        x-small
        v-for="(n, i) in 5"
        :key="i"
      >
        mdi-checkbox-blank-circle
      </v-icon>
    </div>
  </v-main>
</template>

<script>

import { mapState } from 'vuex'
import axios from '@/axios'

export default {
  name: 'Home',
  computed: {
    ...mapState([
      'isMobile',
      'yIndexHome',
      'isLogin'
    ])
  },
  data() {
    return {
      data: [],
      images: [],
      categories: [],
      header: '',
      click: 0,
      index: 0,
      appName: process.env.VUE_APP_NAME
    }
  },
  methods: {
    next (index) {
      if (index === 4 ) {
        this.index = 0
      } else {
        this.index +=1
      }
    },
    // goTo (item) {
    //   this.click = this.click + 1
    //   if (item.is_button === 'NO' && this.click === 1) {
    //     this.$store.commit('setProductType', item.category_name)
    //     this.toShop(item.category_name)
    //   }
    // },
    // scrollIntoView () {
    //   let id = 'row-' + this.yIndexHome
    //   setTimeout(()=> {
    //     var element = document.getElementById(id);
    //     element.scrollIntoView({
    //       behaviour: 'auto',
    //       block: 'center',
    //       inline: 'center'
    //     });
    //     this.$store.commit('setYIndexHome', null)
    //   })
    // },
    // setSliderImage (data) {
    //   let images = []
    //   data.forEach((item, index) => {
    //     if (index === 0) {
    //       item.url_image.forEach(img => {
    //         images.push([img])
    //       })
    //     } else {
    //       item.url_image.forEach((img,i) => {
    //         images[i].push(img)
    //       })
    //     }
    //   })
    //   this.images = images
    // },
    getSlider () {
      this.$store.commit('setIsLoading', true)
      axios.get('/api/v1/pages/home')
        .then(response => {
          this.data = response.data.data.slider
          this.categories = response.data.data.category
          this.header = response.data.data.category_name
          // if (this.yIndexHome) {
          //   this.scrollIntoView()
          // }
        })
        .catch(error => {
          this.errorHandling(error)
        })
        .finally(() => {
          this.$store.commit('setIsLoading', false)
        })
    }
  },
  mounted () {
    this.setMetaInfo(null, null)

    setTimeout(() => {
      if (!this.isLogin) {
        this.$router.push({ name: 'Login'})
      } else {
        // this.getSlider()
      }
    }, 100)

    this.$store.commit('setIsHome', true)
    this.$store.commit('setIsSearch', false)
    this.$store.commit('setIsLoginPage', false)
    this.$store.commit('setIsMemberPage', false)
    this.$store.commit('setActivePage', 'HOME')
  },
  destroyed () {
    this.$store.commit('setHeaderColor', '#fff')
    this.click = 0
  }
}
</script>

<style scoped>
.h-header {
  text-align: center;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.h-title{
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 33px;
  color: #8B1724;
}
.h-title2{
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 16px;
  color: #8B1724;
  margin-top: 25px;
}
.h-title3 {
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 44px;
  color: #8B1724;
  margin-top: 5px;
}
.h-sub {
  position: absolute;
  bottom:10px;
  left:50%;
  transform: translate(-50%, 0);
  width: 100%;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 34px;
  color: #8B1724;
}
.img-wrapper {
  text-align: center;
  width: 400px;
  height: 400px;
  position: relative !important;
  display: block;
  cursor: pointer;
}
.img {
  position: absolute;
  top:50%;
  left:50%;
  transform: translate(-50%,-50%);
}
.number {
  position: absolute;
  top:0;
  left:0;
  color: #8B1724;
}
</style>
